* {
  font-family: 'Inter', sans-serif;
}

// Main Theme
.c-dark-theme {
  // background-image: linear-gradient(to bottom right, #721255, #190A2E, #3D266F);
  background-image: linear-gradient(to bottom right, #00A3C0, #133A61, #EF4053);
}

// Start Screens 
.login-box {
  background-color: rgba(0,0,0,0.3);
  color: white;
}
.login-box .card-footer {
  background-color: transparent;
  border-color: transparent;
}
.login-logo {
  width: 240px;
  height: 120px;
  margin: 0 auto;
  margin-bottom: 20px;
  display: block;
  object-fit: contain;
}
.logo-height {
  height: 30px;
}

// Interactivity
.inter-col {
  padding-top: 20px;
  padding-bottom: 20px;
}
.inter-col-content {
  height: calc(100vh - 100px);
  overflow-y: scroll;
  padding: 0 10px;
}
.inter-type {
  font-weight: 700;
  text-transform: uppercase;
}
.inter-filter {
  width: 160px;
}
.inter-title {
  font-weight: 700;
  letter-spacing: 2px;
}
.inter-content {
  flex: 1;
  overflow-y: scroll;
  padding-top: 30px;
}
.inter-controller {
  min-height: 100px;
}
.inter-opt-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}